import React, { MouseEvent, useState } from 'react';

interface Props {
  termsAccepted: boolean;
  setTermsAccepted: (termsAccepted: boolean) => void;
}

const Optins: React.FC<Props> = (props) => {
  const [showingTerms, setShowingTerms] = useState(false);
  const { termsAccepted, setTermsAccepted } = props;

  const onToggleTermsClick = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    // terms has an anchor link that we use as a click target to toggle showing the terms
    // but if the user clicks anywhere else we toggle the terms
    if (event.target instanceof HTMLAnchorElement) {
      setShowingTerms(!showingTerms);
    } else {
      setTermsAccepted(!termsAccepted);
    }
  };

  const renderTerms = () => {
    if (!showingTerms) return;
    return (
      <div
        className="control-group checkout-submit-notes terms-content"
        id="terms-content"
        style={{ display: 'block' }}
      >
        <div className="submit-note">
          <h3 className="title">{window.Tickit_Checkout_i18n.terms_title}</h3>
          <div dangerouslySetInnerHTML={{ __html: window.Tickit_Checkout_i18n.terms_html }}></div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="control-group checkbox-control-group">
        <div className="control">
          <input
            className="checkbox"
            required={true}
            type="checkbox"
            value="1"
            name="checkout[terms_accepted]"
            id="checkout_terms_accepted"
            checked={termsAccepted}
            onChange={() => {
              setTermsAccepted(!termsAccepted);
            }}
          />
          <label
            htmlFor="checkout_terms_accepted"
            dangerouslySetInnerHTML={{ __html: window.Tickit_Checkout_i18n.terms_prompt }}
            onClick={onToggleTermsClick}
          ></label>
        </div>
      </div>

      <div className="control-group checkbox-control-group">
        <div className="control">
          <input
            className="checkbox"
            type="checkbox"
            value="1"
            name="checkout[optin]"
            id="checkout_optin"
          />
          <label htmlFor="checkout_optin">{window.Tickit_Checkout_i18n.optin_prompt}</label>
        </div>
      </div>

      {renderTerms()}
    </React.Fragment>
  );
};
export default Optins;
