import { googleAnalyticsV4Params } from './analytics';

const VIEW_CONTENT_ACTIONS = [
  'events-index',
  'events-show',
  'events-show_item',
  'events-iframe_toc',
];

const INITIATE_CHECKOUT_ACTIONS = ['checkouts-new'];

const track = function (fbAction: string, params?: object) {
  try {
    if (!window.fbq) return;
    if (!params) params = {};

    window.fbq('track', fbAction, params);
  } catch (e) {
    console.error(e);
  }
};

export default function (body: JQuery<HTMLBodyElement>) {
  VIEW_CONTENT_ACTIONS.forEach((action) => {
    if (!body.hasClass(action)) return;
    track('ViewContent');
  });

  INITIATE_CHECKOUT_ACTIONS.forEach((action) => {
    if (!body.hasClass(action)) return;
    let numItems = 0;
    $('.quantity-input').each(function () {
      const input = $(this) as JQuery<HTMLSelectElement>;

      let qty = input.val();
      if (Array.isArray(qty)) qty = qty[0];
      if (typeof qty === 'string') qty = parseInt(qty, 10);
      if (isNaN(qty)) return;

      numItems += qty;
    });
    const params = googleAnalyticsV4Params();
    params[numItems] = numItems;
    track('InitiateCheckout', params);
  });
}
