import checkoutShippingAddress from './checkoutShippingAddress';
import checkoutUpsell from './checkoutUpsell';
import checkoutWithBackgroundProcessing from './checkoutWithBackgroundProcessing';
import checkoutWithUJS from './checkoutWithUJS';
import checkoutWithStripe from './checkoutWithStripe';
import validateCheckoutForm from './validateCheckoutForm';

export default function () {
  // usually a FORM element except for Stripe, which nests its own FORM element so we use SECTION
  const form = $('#tickit-checkout') as JQuery<HTMLFormElement>;
  if (!form.length) return;

  checkoutShippingAddress(form);
  validateCheckoutForm(form);

  checkoutUpsell();

  checkoutWithStripe(form);
  checkoutWithBackgroundProcessing(form);
  checkoutWithUJS(form);
}
